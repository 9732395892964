.UserPassword .container {
	display: flex;
}

.UserPassword .containerColumn {
	flex-direction: column;
	flex-grow: 1;
}

.UserPassword .input {
	margin: 16px;
}
