.UserEdit .container {
	display: flex;
}

.UserEdit .containerColumn {
	flex-direction: column;
	flex-grow: 1;
}

.UserEdit .input {
	margin: 16px;
}
