.SignIn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;
}

.SignIn form {
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-top: 64px;
}
